"use client";
import { hyphenateForTagular } from "@/utils/tagular/helpers";
import { TagularEventingRequiredProps } from "@/types/Tagular";
import {
  CommonVariants,
  DEFAULT_USE_IN_VIEW_OPTIONS,
  ElementType,
} from "@/constants/tagular/main";
import { ClientOnly } from "@/components/Cohesion/ClientOnly";
import { ElementViewed } from "@/hooks/eventing/types/redventures.usertracking.v3.ElementViewed";
import useElementEvents from "@/hooks/eventing/useElementEvents";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

type ColumnLinkProps = {
  label: string;
  url: string;
  index: number;
  sub_index?: number;
  is_heading: boolean;
} & TagularEventingRequiredProps;

function InnerColumnLink({
  label,
  url,
  is_heading,
  index,
  sub_index,
  html_id,
  location,
}: ColumnLinkProps) {
  const webElement: ElementViewed["webElement"] = {
    elementType: ElementType.Entry,
    htmlId: html_id,
    location: location as string,
    position:
      sub_index === undefined
        ? `${index + 1}`
        : `${index + 1}.${sub_index + 1}`,
    text: label,
    name: hyphenateForTagular(label),
  };

  const { elementViewed, elementClicked } = useElementEvents({ webElement });

  const { inView, ref } = useInView(DEFAULT_USE_IN_VIEW_OPTIONS);

  useEffect(() => {
    if (inView) {
      elementViewed();
    }
  }, [inView, elementViewed]);

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    elementClicked();
  };

  return is_heading ? (
    <a href={url} className="no-underline" ref={ref} onClick={handleClick}>
      <h4 className="my-4 font-bold">{label}</h4>
    </a>
  ) : (
    <a
      key={url}
      className="no-underline hover:underline hover:text-light-foreground text-light-foreground mb-4"
      href={url}
      ref={ref}
      onClick={handleClick}
    >
      {label}
    </a>
  );
}

export default function ColumnLink(props: ColumnLinkProps) {
  return (
    <ClientOnly>
      <InnerColumnLink {...props} />
    </ClientOnly>
  );
}
