"use client";
import ProductCard from "@/components/ProductCard";
import { useHits, useInstantSearch } from "react-instantsearch";
import { useSearchResultCount } from "../SearchContextProvider";
import { useEffect } from "react";

import { ReadonlyURLSearchParams } from "next/navigation";
import Icon from "@/components/icons";
import { CARDS_PER_ROW, RESULT_TYPE_URL_PARAM } from "../constants";
import SearchResultsLoadingPlaceholder from "./ResultsLoading";
import { FormatSubType } from "@/constants/tagular/main";
import { SearchResult } from "../types";
import NoResults, { NoResultsCourses, NoResultsPrograms } from "../NoResults";

interface SearchHitsProps {
  title?: string;
  facetForLink?: string;
  level?: string;
  searchParams: ReadonlyURLSearchParams;
}
function SearchHits({
  title = "",
  facetForLink = "",
  level = "first",
  searchParams,
}: SearchHitsProps) {
  const { items: hits, results } = useHits<SearchResult>();
  const { status } = useInstantSearch();
  const { addToResultCount, setResultCount } = useSearchResultCount();
  useEffect(() => {
    if (results?.nbHits) {
      if (facetForLink) {
        addToResultCount(results.nbHits);
      } else {
        setResultCount(results.nbHits);
      }
    }
    // disabling rule since addToResultCount, setResultCount are functions from the hook that will not change
    // adding them can cause the count to work incorrecly
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results?.nbHits, facetForLink]);

  if (status !== "idle") {
    return <SearchResultsLoadingPlaceholder />;
  } else if (!results?.nbHits) {
    if (!facetForLink) {
      return <NoResults />;
    }
    if (facetForLink === "course") {
      return <NoResultsCourses />;
    }
    if (facetForLink === "program") {
      return <NoResultsPrograms />;
    }
    return null;
  }

  const getTabPageLink = () => {
    const params = new URLSearchParams(Array.from(searchParams.entries()));
    params.set(RESULT_TYPE_URL_PARAM, facetForLink);
    return `search/?${params.toString()}`;
  };
  const allResultsStyles =
    "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 py-4";
  const groupedResultsStyles = "flex flex-nowrap gap-4 py-4";

  return (
    <div>
      {title ? (
        <div className="flex justify-between items-center mt-4 mb-2">
          <h3 className="text-lg my-0">{title}</h3>
          {facetForLink && results?.nbHits ? (
            <a href={getTabPageLink()} className="text-sm flex items-center">
              Show ({results.nbHits})
              <Icon type="chevron-right" />
            </a>
          ) : null}
        </div>
      ) : null}
      <div className="overflow-x-auto">
        <div className={facetForLink ? groupedResultsStyles : allResultsStyles}>
          {hits.map((hit, i) => (
            <div key={hit.objectID} className="flex justify-center">
              <ProductCard
                name={hit.title}
                href={hit.marketing_url.replace(
                  /^https?:\/\/(www\.)?edx\.org/,
                  "",
                )}
                imageUrl={hit.card_image_url || ""}
                type={hit.learning_type?.at(0) as string}
                partnerName={hit.partner?.at(0) as string}
                partnerLogoUrl={hit.owners?.at(0)?.logoImageUrl as string}
                className={facetForLink ? "" : "w-full"}
                location="search-results"
                subjects={hit.subject?.at(0)}
                index={hit.__position - 1}
                formatSubtype={FormatSubType.HorizontallyStacked}
                objectId={hit.objectID}
                queryId={results?.queryID}
                productSource={hit.product_source}
                product={hit.product}
                resultLevel={level}
                uuid={hit.uuid}
                parentTitle={hit.title}
                activeRunKey={hit.active_run_key}
                resultsIndex={results?.index}
                organizationLogoOverride={hit.organization_logo_override}
              />
            </div>
          ))}
          {facetForLink && results?.nbHits ? (
            <div className="flex justify-center lg:hidden">
              <a
                href={getTabPageLink()}
                className="flex items-center bg-info-300 rounded-xl border shadow text-primary text-xl h-[332px] w-[270px] px-8 text-center whitespace-normal"
              >
                <span>{`Show ${results?.nbHits > CARDS_PER_ROW ? "all" : ""} ${results?.nbHits} ${title.toLocaleLowerCase()}`}</span>
              </a>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default SearchHits;
